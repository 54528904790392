import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './locales'

import '@/style/reset.css'
import '@/style/common.scss'
import '@/style/element-ui.scss'

import './plugin/element-ui'

import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import '@/style/vue-phone-component.scss'

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
