export default {
    supplierLoginName: '供应商登录',
    loginNamePlaceholder: '请输入手机号/邮箱',
    loginNameRequired: '请输入手机号/邮箱',
    loginPwdRequired: '请输入密码',
    loginCodeRequired: '请输入验证码',
    loginPwdError: '账户名/密码不正确',
    pwdPlaceholder: '请输入密码',
    authCodePlaceholder: '请输入验证码',
    getAuthCode: '获取验证码',
    login: '登录',
    register: '注册',
    forgetPwd: '忘记密码?',
    registerTitle: '富途采购平台注册',
    registerSubTitle: '富途采购平台邀请您注册',
    registerConfirmInfo: '确认信息',
    registerAccount: '注册账号',
    registerFinish: '完成注册',
    companyType: '企业',
    personType: '个人',
    registerCountry: '注册国家',
    registerCompany: '企业名称',
    creditCode: '统一社会信用代码',
    registerCode: '注册号/认证号',
    inviter: '被邀请人姓名',
    invitee: '邀请人姓名',
    nextStep: '下一步',
    IDNumber: '证件号码',
    residentIdentityCardchinese: '居民身份证（中国）',
    passport: '护照',
    identityCardhongKong: '居民身份证（香港）',
    identityCardMacao: '居民身份证（澳门）',
    teEnterCode: '台湾居民来往大陆通行证',
    selectPlaceholder: '请选择',
    registerName: '名称',
    countryRequire: '请选择注册国家',
    companyRequire: '请填写企业名称',
    nameRequire: '请填写名称',
    creditCodeRequire: '请填写统一社会信用代码',
    IDNumRequire: '请填写证件号码',
    inviterRequire: '请填写被邀请人姓名',
    invalidFormat: '格式不正确',
    mobileRegister: '手机号注册',
    emailRegister: '邮箱注册',
    phoneNumber: '手机号码',
    mobileAuthCode: '手机验证码',
    emailAuthCode: '邮箱验证码',
    email: '电子邮箱',
    passwordInput: '输入密码',
    newPasswordInput: '输入新密码',
    passwordConfirm: '确认密码',
    loginName: '登录名',
    prevStep: '上一步',
    mobileRequired: '请输入手机号',
    securityCodeRequired: '请输入验证码',
    emailRequired: '请输入邮箱',
    passwordRequired: '请输入密码',
    passwordConfirmRequired: '请再次输入密码',
    passwordConfirmInvalid: '密码不一致',
    loginNameRequied: '请输入登录名',
    finish: '完成',
    second: '秒',
    passwordValidate: '密码长度应大于8位，且需包含数字和大小写字母',
    resetAuth: '验证身份',
    resetEnter: '输入新密码',
    resetFinish: '完成重置',
    resetPassword: '重置密码',
    resetSuccess: '重置成功',
}
