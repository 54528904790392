import Vue from 'vue'
import VueI18n from 'vue-i18n'

import CN from './zh-cn'
import EN from './en-us'

export enum LANG {
    'CN' = 'zh-CN',
    'US' = 'en-US',
}

Vue.use(VueI18n)

// 引入语言包内容
const locales: Record<string, any> = {}
locales[LANG.CN] = CN
locales[LANG.US] = EN

export const COOKIE_KEY_LANG = 'gateway_language'

export const COOKIE_DOMAIN_LANG = '.futuoa.com'

export const LANG_LIST: string[] = [LANG.CN, LANG.US]

export function getLocaleFromCookie () {
    let lang: any = null
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
        const cookieStr = cookies[i]
        const cookiePart = cookieStr.trim().split('=')
        if (cookiePart[0] === COOKIE_KEY_LANG) {
            const val: any = cookiePart[1]
            if (LANG_LIST.includes(val)) {
                lang = val
            }
            break
        }
    }
    return lang
}

export function setLocaleCookie (locale: string) {
    // 过期时间一年
    const MaxAge = 365 * 24 * 3600
    console.log('NODE_ENV----', process.env.NODE_ENV)
    const domain = process.env.NODE_ENV === 'development' ? '' : `domain=${COOKIE_DOMAIN_LANG}`
    document.cookie = `${COOKIE_KEY_LANG}=${locale};max-age=${MaxAge};path=/;${domain}`
}

// 语言文件栏加载
const i18n = new VueI18n({
    locale: getLocaleFromCookie() || LANG.CN,
    fallbackLocale: LANG.CN,
    messages: locales,
    silentFallbackWarn: true,
})

export default i18n
