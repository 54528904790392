import Vue from 'vue'
import { Button, Input, Popover, Form, FormItem, Select, Option, Message } from 'element-ui'

Vue.use(Button)
Vue.use(Input)
Vue.use(Popover)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Select)
Vue.use(Option)

Vue.prototype.$message = Message
