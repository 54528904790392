/* eslint-disable max-len */
export default {
    supplierLoginName: 'Supplier login',
    loginNamePlaceholder: 'Please enter your mobile phone number/email address',
    loginNameRequired: 'Please enter the mobile phone number/email address',
    loginPwdRequired: 'Please enter the password',
    loginCodeRequired: 'Please enter the verification code',
    loginPwdError: '账户名/密码不正确',
    pwdPlaceholder: 'Please enter your password',
    authCodePlaceholder: 'Please enter the verification code',
    getAuthCode: 'get verification code',
    login: 'Login',
    register: 'Register',
    forgetPwd: 'Forget the password?',
    registerTitle: '富途采购平台注册',
    registerSubTitle: '富途采购平台邀请您注册',
    registerConfirmInfo: 'Confirm',
    registerAccount: 'Register',
    registerFinish: 'Complete',
    companyType: 'Company',
    personType: 'Individual',
    registerCountry: 'Country/Region',
    registerCompany: 'Company Name',
    creditCode: 'Uniform Social Credit Code',
    registerCode: 'Reg.No.',
    inviter: 'Invitee',
    invitee: 'Invited by',
    nextStep: 'Next',
    IDNumber: 'ID Number',
    residentIdentityCardchinese: 'Chinese ID card',
    passport: 'Passport',
    identityCardhongKong: 'HK ID card',
    identityCardMacao: 'Macao ID card',
    teEnterCode: 'Mainland travel permit for Taiwan residents',
    selectPlaceholder: 'Please Select',
    registerName: 'name',
    countryRequire: 'Please select Country/Region',
    companyRequire: 'Please enter the company name',
    nameRequire: 'Please enter the name',
    creditCodeRequire: 'Please enter the Uniform Social Credit Code',
    IDNumRequire: 'Please enter the ID Number',
    inviterRequire: 'Please enter the invitee',
    invalidFormat: 'incorrect format',
    mobileRegister: 'Mobile',
    emailRegister: 'Email',
    phoneNumber: 'Mobile number',
    mobileAuthCode: 'SMS verification code',
    emailAuthCode: 'Email verification code',
    email: 'Email Address',
    passwordInput: 'Password',
    newPasswordInput: 'New Passoword',
    passwordConfirm: 'Confirm Password',
    loginName: 'Username',
    prevStep: 'Last',
    mobileRequired: 'Please enter the mobile',
    securityCodeRequired: 'Please enter the verification code',
    emailRequired: 'Please enter the email address',
    passwordRequired: 'Please enter the password',
    passwordConfirmRequired: 'Please enter the password again',
    passwordConfirmInvalid: 'Inconsistent, please re-enter',
    loginNameRequied: 'Please enter the username',
    finish: 'Complete',
    second: '秒',
    passwordValidate: 'Password must be at least 8 characters long and contain at least one uppercase character, at least one lowercase character.',
    resetAuth: 'Authentication',
    resetEnter: 'Reset password',
    resetFinish: 'Complete',
    resetPassword: 'Reset password',
    resetSuccess: 'Complete',
}
